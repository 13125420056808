import {
    get,
    editpost,
    post
} from '../http.js'

const party = {
    getCount(data) {
        return get('/pc/activity/count', data)
    },
    getTableData(data) {
        // console.log(data)
        return get('/pc/activity/list', data)
    },
    addTableData(data){
        // console.log(data);
        return editpost('/pc/activity/save', data,'qdactivity')
    },
    editTableData(data){
        // console.log(data);
        return editpost('/pc/activity/update', data,'qdactivity')
    },
    getRowInfo(data){
        return get('/pc/activity/getById', data)
    },
    getSelList(data){
        return get('/pc/activity/getSelList', data)
    },
    getUserList(data){
        return get('/pc/activity/userList', data)
    },
    getUserNum(data){
        return get('/pc/activity/getUserSum', data)
    },
    getUserExport(data){
        return get('/pc/activity/userExport', data)
    },
    partyReturn(data){
        return get('/pc/activity/down', data)
    }

}

export default party;