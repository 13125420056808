import {
    get,
    editpost,
    post
} from '../http.js'

const managehome = {
    //整体情况
    schoolentirety(data) {
        return post('/jw/qudao/v1/schoolentirety', data)
    },
    //筛查计划下拉框
    screeningsel(data) {
        return post('/jw/qudao/v1/screeningsel', data)
    },
    //各学校筛查进度
    performance(data) {
        return post('/education/report/v1/performance', data)
    },
    //筛查情况
    screeningAnalyst(data) {
        return post('/education/report/v1/screeningAnalyst', data)
    },
}

export default managehome;