import {
    get,
    post,
    postfile
} from '../http.js'

const bl = {
     //整体视力不良占比 e3
    abnormal(data) {
        return post('/education/report/v1/abnormal', data)
    },
    //学校完成度 e1
    saccomplish(data) {
        return post('/education/bi/v1/saccomplish', data)
    },
    //学生完成度 e2
    cperformance(data) {
        return post('/education/bi/v1/cperformance', data)
    },
    // 全区学校视力状态 e9
    svisionDistribution(data) {
        return post('/education/report/v1/svisionDistribution', data)
    },
    // 各年级视力不良占比 e10
    clvision(data) {
        return post('/education/report/v1/clvision', data)
    },
    //统计数据
    statisticsData(data) {
        return post('/education/bi/v1/statisticsData', data)
    },
    //获取传参字段
    screeningsel(data) {
        return post('/jw/qudao/v1/screeningsel', data)
    },

}

export default bl;