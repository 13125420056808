import {
    post,
    postfile,
} from '../http.js'

const uuser = {
    //导入
    importScr(data,timeouts){
        return postfile('/pc/rec/importScr',data,timeouts)
    },
    //列表
    list(data) {
        return post('/pc/rec/list', data)
    },
}

export default uuser;