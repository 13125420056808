import {
    get,
    editpost,
    post
} from '../http.js'

const detailes = {
    // 根据登录人获取账户信息
    getById(data) {
        return get('/pc/qd/getById', data)
    },
    // 根据id获取用户统计
    userReport(data) {
        return post('/pc/qd/userReport', data)
    },
    // 编辑
    update(data) {
        return post('/pc/qd/update', data,)
    },
    //地域字典数据
    getAreaList(data) {
        return post('/dict/getCity', data,)
    }
    
}

export default detailes;