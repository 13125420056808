import {
    get,
    editpost,
    post
} from '../http.js'

const vision = {
    getCount(data) {
        return get('/pc/jf/v1/getCount', data)
    },
    getList(data) {
        return post('/pc/jf/v1/getList', data)
    },
    getInfoList(data) {
        return post('/wsj/getInfoList', data)
    },
    getInfo(data) {
        return post('/wsj/getInfo', data)
    },
    submitDataStart(data) {
        return post('/wsj/sub', data)
    },
    getZDdata(data) {
        return post('/dict/getinfo', data)
    },
    sub(data) {
        return post('/wsj/sub', data)
    },


}

export default vision;