import {
    get,
    editpost,
    post,
    postfile
} from '../http.js'

const message = {
    // 点击编辑获取信息
    get(data) {
        return get('/bfapi/bfnews/info', data)
    },
    // 更新表格数据
    updateList(data) {
        return post('/bfapi/bfnews/list', data)
    },
    // 新增
    add(data) {
        return editpost('/bfapi/bfnews/add', data, 'bfnews')
    },
    // 编辑
    edit(data) {
        return editpost('/bfapi/bfnews/update', data, 'bfnews')
    },
    // 删除
    del(data) {
        return post('/bfapi/bfnews/del', data)
    },
    bffiles(data){
        return postfile('/bfapi/bffiles/addfile',data)
    },

    //消息统计接口
     theTitle(data) {
        return post('/june/message/theTitle', data)
    },
   // 客服回复消息接口
    kfsend(data) {
        return post('/june/message/kfsend', data)
    },
    //客服对应相关消息列表
    kflist(data) {
        return post('/june/message/kflist', data)
    },
    //当前用户未读数量
    kfunread(data) {
        return post('/june/message/kfunread', data)
    },
    //消息管理中列表
    listuser(data) {
        return post('/june/message/listuser', data)
    },
      //获取渠道名称
      qdmid(data) {
        return post('/june/message/qdmid', data)
    }





}

export default message;