//引入相关api管理模块
import jmtest from './apis/jmtest'
import bfnews from './apis/news'
import vipuser from './apis/vipuser'
import uuser from './apis/uuser'
import count from './apis/count'
import detailes from './apis/detaile'
import equipment from './apis/equipment'
import equiUser from './apis/equiUser'
import vision from './apis/vision'
import channel from './apis/Channel'
import vip from './apis/vip'
import message from './apis/message'
import article from './apis/article'
import sc from './apis/school'
import managehome from './apis/managehome'
import exInfo from './apis/exInfo'
import bl from './apis/bl'
import screening from './apis/screening'
import {
    login,
    user,
    job,
    role,
    menu,
    dept
} from './apis/sys'
import party from './apis/party'
//进行统一导出
export default {
    login, // 登录
    jmtest, // 测试
    user, // 用户管理
    job, // 职位管理
    role, // 角色管理
    menu, // 模块管理
    dept, // 组织机构
    bfnews,//新闻管理
    vipuser,//用户管理
    uuser,
    count,
    detailes,
    equipment,//设备管理
    vision,//镜方审核
    channel,//渠道管理
    vip,
    message,
    article,
    equiUser,//设备用户
    party,//活动
    sc,//学校管理
    managehome,//管理首页
    exInfo,
    bl,
    screening
}