import {
    get,
    editpost,
    post,
    postfile
} from '../http.js'

const equipment = {
    // 获取统计信息
    getReport(data) {
        return post('/pc/dev/v1/report', data)
    },
    // 获取入库列表
    getInList(data) {
        return post('/pc/dev/v1/getInList', data)
    },
    // 获取发货列表
    getOutList(data) {
        return post('/pc/dev/v1/getOutList', data,)
    },
    //获取市场列表
    getList(data) {
        return post('/pc/dev/v1/getList', data,)
    },
    //市场列表导出
    exportList(data) {
        return post('/pc/dev/v1/exportList', data,)
    },
    // 获取市场统计
    getReport1(data) {
        return post('/pc/dev/v1/report1', data)
    },
    uploadDevIn(data) {
        console.log(data);
        return postfile('/pc/dev/v1/devIn', data)
    },
    uploadDevOut(data) {
        console.log(data);
        return postfile('/pc/dev/v1/devOut', data)
    },
    // 市场管理导出
    exportScr(data) {
        return post('/pc/dev/v1/exportScr', data)
    },
    
}

export default equipment;