import {
    get,
    editpost,
    post,
    postfile
} from '../http.js'

const equiUser = {
    // 获取统计信息
    getReport(data) {
        console.log(data);
        return post('/pc/devuser/v1/report1', data)
    },
    // 获取入库列表
    getList(data) {
        return post('/pc/devuser/v1/getList', data)
    },

}

export default equiUser;