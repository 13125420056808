import {
    get,
    editpost,
    post,
    postfile
} from '../http.js'

const article = {
    // 点击编辑获取信息
    // get(data) {
    //     return get('/pc/consult/getInfo', data)   
    // },
       // 点击编辑获取信息
    getInfo(data) {
        return post('/pc/consult/v3/getInfo', data)
    },
    // 更新表格数据
    updateList(data) {
        return post('/pc/consult/getList', data)
    },
    // 新增
    // add(data) {
    //     return post('/pc/consult/save', data)
    // },
    add(data) {
        return post('/pc/consult/v3/add', data)
    },
    // 编辑
    edit(data) {
        // return post('/pc/consult/update', data)
       console.log("edit=>",data);
        return post('/pc/consult/v3/update', data)
    },
    // 删除
    del(data) {
        // return post('/pc/consult/dels', data)
        return post('/pc/consult/v3/del1', data)
    },
    // 根据类型获取字典内容
    getListByType(data) {
        return post('/dict/getListByType', data)
    },
    getinfo(data) {
        return post('/dict/getinfo?dtype=yj_consult', data)
    },
}

export default article;