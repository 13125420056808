import {
    get,
    editpost,
    post,
    postfile
} from '../http.js'

const bfnews = {
    // 点击编辑获取信息
    get(data) {
        return get('/bfapi/bfnews/info', data)
    },
    // 更新表格数据
    updateList(data) {
        return post('/bfapi/bfnews/list', data)
    },
    // 新增
    add(data) {
        return editpost('/bfapi/bfnews/add', data, 'bfnews')
    },
    // 编辑
    edit(data) {
        return editpost('/bfapi/bfnews/update', data, 'bfnews')
    },
    // 删除
    del(data) {
        return post('/bfapi/bfnews/del', data)
    },
    bffiles(data){
        return postfile('/bfapi/bffiles/addfile',data)
    }
}

export default bfnews;