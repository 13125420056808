import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

const routes = [{
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    children: [{
      path: '/',
      name: 'work',
      component: () => import('../views/workdata.vue')
    },{
      path: '/work',
      name: 'work',
      component: () => import('../views/workdata.vue')
    },
    {
      path: '/managementhome',
      name: 'managementhome',
      component: () => import('../views/managementhome.vue')
    },
  ]
  },

  {
    path: '/bl',
    name: 'bl',
    component: () => import('../views/bl/bl.vue')
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: () => import('../views/mobile/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue'),
    children: [{
        path: '/work',
        name: 'work',
        component: () => import('../views/workdata.vue')
      },{
      path: '/editpwd',
      name: 'editpwd',
      component: () => import('../views/system/userinfo/index.vue')
    }]
  },
  {
    path: '/system',
    name: 'system',
    component: () => import('../views/Home.vue'),
    children: [{
        path: '/',
        name: 'sysmenu',
        component: () => import('../views/system/tree_menu/index.vue')
      },
      {
        path: '/sysmenu',
        name: 'sysmenu',
        component: () => import('../views/system/tree_menu/index.vue')
      },
      {
        path: '/sysdept',
        name: 'sysdept',
        component: () => import('../views/system/tree_dept/index.vue')
      },
      {
        path: '/sysjob',
        name: 'sysjob ',
        component: () => import('../views/system/tab_job/index.vue')
      },
      {
        path: '/sysrole',
        name: 'sysrole',
        component: () => import('../views/system/tab_role/index.vue')
      },
      {
        path: '/sysuser',
        name: 'sysuser',
        component: () => import('../views/system/tab_user/index.vue'),
      },  
    ]
  },
  {
    path: '/yw',
    name: 'yw',
    component: () => import('../views/Home.vue'),
    children: [
      {
        //用户管理
        path: '/vipuser',
        name: 'vipuser',
        component: () => import('../views/yw/vipuser/index.vue'),
      },
      {
        path: '/vipinfo',
        name: 'vipinfo',
        component: resolve => require(['../views/bf/Vip/VipInfo.vue'],resolve)
      }, 
      {
        //用户档案
        path: '/share',
        name: 'share',
        component: () => import('../views/yw/vipuser/share.vue'),
      },
      {
        //新增修改档案
        path: '/information',
        name: 'information',
        component: () => import('../views/yw/vipuser/information.vue'),
      },
      {
        //修改档案
        path: '/oldinformation',
        name: 'oldinformation',
        component: () => import('../views/yw/vipuser/oldinformation.vue'),
      },
      {
        //用户档案本期
        path: '/user',
        name: 'user',
        component: () => import('../views/yw/user/index.vue'),
      },
      {
        //批量导入页
        path: '/pldr',
        name: 'pldr',
        component: () => import('../views/yw/user/pldr.vue'),
      },
      {
        //账户信息
        path: '/detaSee',
        name: 'detaSee',
        component: () => import('../views/yw/details/detaSee.vue'),
      },
      {
        //账户信息修改
        path: '/detaEdit',
        name: 'detaEdit',
        component: () => import('../views/yw/details/detaEdit.vue'),
      },
      {
        //设备管理
        path: '/equipment',
        name: 'equipment',
        component: () => import('../views/yw/equipment/index.vue'),
      },
      {
        //发货管理
        path: '/returnGoods',
        name: 'returnGoods',
        component: () => import('../views/yw/equipment/returnGoods.vue'),
      },
      {
        //市场管理
        path: '/market',
        name: 'market',
        component: () => import('../views/yw/equipment/market.vue'),
      },
      {
        //设备用户
        path: '/equiUser',
        name: 'equiUser',
        component: () => import('../views/yw/equiUser/index.vue'),
      },
      {
        //镜方审核
        path: '/visionMirror',
        name: 'visionMirror',
        component: () => import('../views/yw/visionMirror/index.vue'),
      },
      {
        //镜方详情
        path: '/visionMirrorData',
        name: 'visionMirrorData',
        component: () => import('../views/yw/visionMirror/visionData.vue'),
      },
      
      {
        path: '/error',
        name: 'error',
        component: () => import('../views/yw/error/error.vue')
      },
      {
        //渠道管理
        path: '/channel',
        name: 'Channel',
        component: () => import('../views/yw/qd/channel.vue'),
      },
      {
        path: '/vips',
        name: 'vips',
        component: resolve => require(['../views/bf/Vip/index.vue'],resolve),
        meta:{
          keepAlive:true,
        }
      }, 
      //活动管理
      { 
        path: '/party',
        name: 'party',
        component: () => import('../views/yw/party/index.vue'),
      }, 
      //活动编辑
      {
        path: '/partyDialog',
        name: 'partyDialog',
        component: () => import('../views/yw/party/dialogVue.vue'),
      }, 
      //活动查看
      {
        path: '/partyInfo',
        name: 'partyInfo',
        component: () => import('../views/yw/party/info.vue'),
      },
      {
        //渠道管理
        path: '/info',
        name: 'info',
        component: () => import('../views/yw/qd/info.vue'),
      },
      {
        //学校管理
        path: '/school',
        name: 'school',
        component: () => import('../views/school.vue'),
      },
      {
        path: '/screening',
        name: 'screening',
        component: () => import('../views/screening/list.vue')
      },
    ]
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue')
  },
  {
    //委员导出
    path: '/exInfo',
    name: '委员导出',
    component: () => import('../views/yw/exInfo/exInfo.vue'),
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let islogin = true
  let token = sessionStorage.getItem('token')
  if (token == null) {
    islogin = false
  }
  if (to.path !== '/login' && to.path !== '/mobile') {
    if (!islogin) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else {
    next()
  }

})


export default router