import {
    get,
    post
} from '../http.js'

// 身高体重基础数据
const sc = {

    getCity(data) {
        console.log("city==>",data);
        return post('/dict/getCity', data)
    },
    list(data) {
        console.log("listlist",data);
        return post('/jw/qdschool/v1/list', data)
    },
}

export default sc;