import {
    get,
    editpost,
    post,
    postfile
} from '../http.js'

// 身高体重基础数据
const vip = {
    // 更新表格数据
    updateList(data) {
        return post('/usermanage/listelder', data)
    },
    // 获取用户基本信息
    getVipInfo(data) {
        return get('/pc/user/v2/userinfo', data)
    },
    // 获取用户孩子列表
    getVipChlilds(data){ 
        return get('/yj_listOfUser/listkids', data)
    },
    // 根据kid获取孩子基本信息
    getVipChlildInfo(data){ 
        return get('/pc/user/v2/kidsHomePage', data)
    },
    // 根据kid获取档案详情
    getVipChlildRecord(data){ 
        return get('/pc/user/v2/kidsListLogs', data)
    },
    // 根据kid获取档案表格
    get_vip_table_list(data){ 
        return get('/pc/kidsLogs/kidsListEx', data)
    },
    // 根据kid获取矫正记录
    getVipChlildCorrs(data){ 
        return get('/pc/user/v2/kidsListCorr', data)
    },
    // 根据kid获取折线图趋势数据
    get_chart_yz(data){
        return get('/pc/user/v2/shareAxis', data)
    },
    // 根据kid获取折线图趋势数据
    get_chart_ly(data){ 
        return get('/pc/user/v2/shareEye', data)
    },
    // 根据kid获取折线图趋势数据
    get_chart_qgd(data){ 
        return get('/pc/user/v2/shareDip', data)
    },
    // 根据kid获取成就信息
    getVipChlildCjs(data){ 
        return get('/pc/user/v2/assessmentRecords', data)
    },
    // 根据kid获取评估记录
    getVipChlildSsica(data){ 
        return get('/pc/user/v2/quAssessmenData', data)
    },
    // 获取报名列表
    getAList(data){
        return get('/pc/user/v2/getAList', data)
    },
    // 报名审核
    getDown(data){
        return get('/pc/activity/down1', data)
    },
    // 修改用户信息
    getUpdate1(data){
        // console.log(data)
        return get('/pc/user/src/update1', data)
    },
    // 获取用户信息
    getById1(data){
        return get('/pc/user/src/getById1', data)
    },
}

export default vip;