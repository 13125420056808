<template>
    <div v-if="this.$parent.$parent.formShow">
        <el-form :label-position="labelPosition" label-width="auto" :size="tabsize" :model='formInfo'>
            <el-row>
                <el-col :span='22' :push='1'>
                    <el-form-item label="标题">
                        <el-input v-model="formInfo.ntitle" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='22' :push='1'>
                    <el-form-item label="日期">
                        <el-date-picker v-model="formInfo.ndate" value-format="yyyy-MM-dd" type="date"
                            placeholder="选择日期" end-placeholder=''>
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="article">
                <!-- <el-upload class="avatar-uploader" :action="serverUrl" name="img" :headers="header"
                    :show-file-list="false" :on-success="uploadSuccess" :on-error="uploadError"
                    :before-upload="beforeUpload">
                </el-upload> -->
                <el-row>
                    <el-col :span='22' :push='1'>
                        <p>内容:</p>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span='22' :push='1'>
                        <quill-editor class="editor" v-model="formInfo.content" ref="myQuillEditor"
                            :options="editorOption" @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
                            @change="onEditorChange($event)">
                        </quill-editor>
                    </el-col>
                </el-row>
            </div>


            <el-row style="margin-top: 20px;">
                <el-col :span='22' :push='1'>
                    <el-button type="primary" :size="tabsize" @click="prim">保存并提交</el-button>
                </el-col>
            </el-row>

        </el-form>
        <div :visible.sync="videoUploadTag">
            <el-dialog width="50%" id="video_upload" style="margin-top: 1px" title="视频上传" :visible.sync="videoUploadTag"
                append-to-body>
                <el-input v-model="videoLink" placeholder="请输入视频链接" clearable></el-input>
                <el-button type="primary" size="small" style="margin: 20px 0px 0px 0px " @click="addVideoLink()">添加
                </el-button>
            </el-dialog>
        </div>
    </div>
</template>
<script>
    // 工具栏配置
    const toolbarOptions = [
        ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
        ["blockquote", "code-block"], // 引用  代码块
        [{
            header: 1
        }, {
            header: 2
        }], // 1、2 级标题
        [{
            list: "ordered"
        }, {
            list: "bullet"
        }], // 有序、无序列表
        [{
            script: "sub"
        }, {
            script: "super"
        }], // 上标/下标
        [{
            indent: "-1"
        }, {
            indent: "+1"
        }], // 缩进
        // [{'direction': 'rtl'}],                         // 文本方向
        [{
            size: ["small", false, "large", "huge"]
        }], // 字体大小
        [{
            header: [1, 2, 3, 4, 5, 6, false]
        }], // 标题
        [{
            color: []
        }, {
            background: []
        }], // 字体颜色、字体背景颜色
        [{
            font: []
        }], // 字体种类
        [{
            align: []
        }], // 对齐方式
        ["clean"], // 清除文本格式
        ["link", "image", "video"] // 链接、图片、视频
    ];
    import {
        quillEditor
    } from "vue-quill-editor";
    import {
        Quill
    } from "vue-quill-editor";
    import "quill/dist/quill.core.css";
    import "quill/dist/quill.snow.css";
    import "quill/dist/quill.bubble.css";
    import Video from './video.js'
    Quill.register(Video, true)

    export default {
        // props: {
        //     /*编辑器的内容*/
        //     value: {
        //         type: String
        //     },
        //     /*图片大小*/
        //     maxSize: {
        //         type: Number,
        //         default: 4000 //kb
        //     }
        // },
        components: {
            quillEditor
        },
        mounted() {
            // console.log(this.formInfo)
        },
        data() {
            var self = this;
            return {
                content: '',
                quillUpdateImg: false, // 根据图片上传状态来确定是否显示loading动画，刚开始是false,不显示
                videoUploadTag: false, // 插入视频选择框
                videoLink: "", //弹框插入的视频链接记录
                labelPosition: 'right',
                editorOption: {
                    placeholder: "",
                    theme: "snow", // or 'bubble'
                    placeholder: "您想说点什么？",
                    modules: {
                        toolbar: {
                            container: toolbarOptions,
                            // container: "#toolbar",
                            handlers: {
                                image: function (value) {
                                    if (value) {
                                        // 触发input框选择图片文件
                                        document.querySelector(".avatar-uploader input").click();
                                    } else {
                                        this.quill.format("image", false);
                                    }
                                },
                                link: function (value) {
                                    if (value) {
                                        var href = prompt('请输入url');
                                        this.quill.format("link", href);
                                    } else {
                                        this.quill.format("link", false);
                                    }
                                },
                                video: function (value) {
                                    self.videoUploadTag = true
                                },
                            }
                        }
                    }
                },
                serverUrl: "", // 这里写你要上传的图片服务器地址
                header: {
                    // token: sessionStorage.token
                } // 有的图片服务器要求请求头需要有token
            };
        },
        props: ['formInfo'],
        methods: {
            prim() {
                // this.$emit('info', this.formInfo)
                this.$parent.$parent.loading = true
                this.$parent.$parent.formShow = false
                let url
                let str
                if (this.formInfo.id) {
                    url = this.$api.bfnews.edit
                    str = '修改'
                } else {
                    url = this.$api.bfnews.add
                    str = '添加'
                }
                url(this.formInfo).then(res => {
                    console.log(res);
                    this.$parent.$parent.loading = false
                    if (res.status == 1) {
                        this.$notify.success({
                            title: 'Info',
                            message: str + '成功',
                            showClose: false,
                            duration: 2000,
                        });
                        this.$parent.$parent.updateList()
                    } else {
                        this.$notify.error({
                            title: 'Info',
                            message: res.msg,
                            showClose: false,
                            duration: 2000,
                        });
                    }
                }).catch((error) => {
                    console.log(error);
                })
                // console.log(this.formInfo);
            },
            onEditorBlur() {
                //失去焦点事件
            },
            onEditorFocus() {
                //获得焦点事件
            },
            onEditorChange() {
                //内容改变事件
            },

            // 富文本图片上传前
            beforeUpload() {
                // 显示loading动画
                this.quillUpdateImg = true;
            },
            // 视频选择完成按钮
            addVideoLink() {
                if (this.videoLink.length == 0) {
                    alert('请输入视频链接');
                }
                //当编辑器中没有输入文本时，这里获取到的 range 为 null
                var range = this.$refs.myQuillEditor.quill.getSelection();
                //视频插入在富文本中的位置
                var index = 0;
                if (range == null) {
                    index = 0;
                } else {
                    index = range.index;
                }
                //隐藏弹框
                this.videoUploadTag = false;
                //将视频链接插入到当前的富文本当中
                this.$refs.myQuillEditor.quill.insertEmbed(index, 'video', this.videoLink);
            },

            uploadSuccess(res, file) {
                // res为图片服务器返回的数据
                // 获取富文本组件实例
                let quill = this.$refs.myQuillEditor.quill;
                // 如果上传成功
                if (res.code == 200) {
                    // 获取光标所在位置
                    let length = quill.getSelection().index;
                    // 插入图片  res.url为服务器返回的图片地址
                    quill.insertEmbed(length, "image", res.url);
                    // 调整光标到最后
                    quill.setSelection(length + 1);
                } else {
                    this.$message.error("图片插入失败");
                }
                // loading动画消失
                this.quillUpdateImg = false;
            },
            // 富文本图片上传失败
            uploadError() {
                // loading动画消失
                this.quillUpdateImg = false;
                this.$message.error("图片插入失败");
            }
        },
        // 监听尺寸按钮变化 改变表格大小
        computed: {
            tabsize() {
                return this.$store.state.tabsize
            }
        },
    };
</script>

<style>
    .ql-tooltip {
        left: 0 !important;
    }
    .ql-container{
        min-height: 300px;
    }
    .editor {
        line-height: normal !important;
        /* height: 800px; */
    }

    .ql-snow .ql-tooltip[data-mode="link"]::before {
        content: "请输入链接地址:";
    }

    .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
        border-right: 0px;
        content: "保存";
        padding-right: 0px;
    }

    .ql-snow .ql-tooltip[data-mode="video"]::before {
        content: "请输入视频地址:";
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item::before {
        content: "14px";
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
        content: "10px";
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
        content: "18px";
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
        content: "32px";
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item::before {
        content: "文本";
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
        content: "标题1";
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
        content: "标题2";
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
        content: "标题3";
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
        content: "标题4";
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
        content: "标题5";
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
        content: "标题6";
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item::before {
        content: "标准字体";
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
        content: "衬线字体";
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
        content: "等宽字体";
    }
</style>