import {
    get,
    post,
    postfile
} from '../http.js'

const channel = {
    getlist(data) {
        return post('/pc/qd/v1/getList', data)
    },
    getnum(data) {
        return post('/pc/qd/v1/report', data)
    },
    save(data) {//新增
        console.log(data);
        return post('/pc/qd/v1/save', data)
    },
    getinfo(data) {//字典
        // console.log(data);
        return post('/dict/getinfo', data)
    },
    getById(data) {//id，获取详情
        return post('/pc/qd/v1/getById', data)
    },
    userReport(data) {//
        return post('/pc/qd/v1/userReport', data)
    },
    upStatus(data) {//
        return post('/pc/qd/v1/upStatus', data)
    },
    update(data) {//
        console.log("提交", data);
        return post('/pc/qd/v1/update', data)
    },
    report1(data) {//
        return post('/pc/qd/v1/report1', data)
    },



}

export default channel;