import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./styles/tree.css"
import "./styles/bf.css"
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts
import axios from 'axios'
Vue.prototype.$http = axios
import api from './requset/api'
Vue.prototype.$api = api
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

// import socket from './socket/socket'
// Vue.prototype.$socket = socket

// import less from 'less'
// Vue.use(less)

import {VueJsonp} from 'vue-jsonp'
Vue.use(VueJsonp)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 引入vant组件库
// import Vant from 'vant';
// import 'vant/lib/index.css';
// Vue.use(Vant);

//富文本编辑器
// import VueQuillEditor from 'vue-quill-editor'
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
// Vue.use(VueQuillEditor);
import  Editor from './components/quillEditor/index.vue';
Vue.component("Editor",Editor);

Vue.config.productionTip = false

//图标选择器
import iconPicker from 'vue-fontawesome-elementui-icon-picker';
Vue.use(iconPicker);



Vue.filter('age', function (value) {
 var date = value/12;
 return  Math.trunc(date);
})

Vue.filter('sex', function (value) {
  switch (value) {
    case 1:
      value = "男";
      break;
    case 2:
      value = "女";
      break
  }
    return value;
})


Vue.filter('utype', function (value) {
  switch (value) {
    case 1:
      value = "本店";
      break;
    case 2:
      value = "其他";
      break;
      case 3:
        value = "二级";
        break
  }
    return value;
})


Vue.filter('timestampToTime', function (value) {
      if(value <=0){
        return "- -"
    }
    var date = new Date(value);
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
    var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate());
    return Y+M+D;
})

//时间戳转换为YMD格式
Vue.filter('tick2ymd', function( tick ){
  var date = new Date(tick);
	var Y = date.getFullYear() + '-';
	var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
	var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
  var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
	var m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
	var s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
  return Y + M + D + ' ' + h + m + s
})




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


Vue.prototype.$table2Tree = function (table) {
  let treeroot = {
    id: 0,
    pid: 0,
    children: []
  }
  for (let i = 0; i < table.length; i++) {
    let row = table[i]
    if (this.$processTable2Tree(treeroot, row)) continue;
  }
  return treeroot.children
}

Vue.prototype.$processTable2Tree = function (currNode, node) {
  if (currNode.id == node.pid) {
    if (currNode.children == null) currNode.children = [];
    currNode.children.push(node);
    return true;
  }
  if (currNode.children != null) {
    for (let i = 0; i < currNode.children.length; i++) {
      let cNode = currNode.children[i];
      if (this.$processTable2Tree(cNode, node)) return true;
    }
  }
  return false;
}

Vue.prototype.$setTableHeight = function (){
    setTimeout(() => {
        // 计算表格高度 100% - 搜索栏高度 - 分页器高度
        this.$nextTick(() => {
          let headerHeight = document.getElementsByClassName('teb_header')[0].clientHeight;
          let tableDom = document.getElementsByClassName('el-table')[0]
          tableDom.style.cssText += `height:calc(100% - ${headerHeight + 55}px);`
        })
    },130);
    
} 

Vue.prototype.$setTableHeightMax = function (){
  setTimeout(() => {
      // 计算表格高度 100% - 搜索栏高度 - 分页器高度
      this.$nextTick(() => {
        let headerHeight = document.getElementsByClassName('top_div')[0].clientHeight;
        let tableDom = document.getElementsByClassName('center_div')[0]
        tableDom.style.cssText += `height:calc(100% - ${headerHeight + 40}px);`
      })
  },130);
  
} 

//设置elementUI el-table的高度
//计算表格高度 100% - 搜索栏高度 - 分页器高度
Vue.prototype.$setTableHeight2 = function (topDivClass,tableDivClass){
  setTimeout(() => {
      this.$nextTick(() => {
        let headerHeight = document.getElementsByClassName(topDivClass)[0].clientHeight;
        let tableDom = document.getElementsByClassName(tableDivClass)[0]
        tableDom.style.cssText += `height:calc(100% - ${headerHeight + 35}px);`
      })
  },130);
}

//错误提示   message 提示文本
Vue.prototype.$alertError = function (message){
  this.$notify.error({
      title: 'error',
      message: message ? message : '失败',
      showClose: false,
      duration: 2000,
  });
}

//成功提示   message 提示文本
Vue.prototype.$alertSuccess = function (message){
  this.$notify.success({
      title: 'success',
      message: message ? message : '成功',
      showClose: false,
      duration: 2000,
  });
}


Vue.filter('visadress', function (value) {

    // this.$api.vipuser.getinfo({
    //     dtype: qd_type
    // })

  switch (value) {
      case 1:
      value = "眼镜店";
            break
      case 2:
      value = "医院";
            break
      case 3:
      value = "视保中心";
            break
      case 4:
      value = "学校筛查";
            break
      case 10:
      value = "渠道";
          break
  }
    return value;
})

//apk类型过滤器
Vue.filter('apkUpdate', function( data ){
  // if(data == ''){
  //   return "/"
  // }
  // if(data == null){
  //   return "/"
  // }else{
  //  return  data
  // }
  switch (data) {
    case "":
      data = "/";
          break
    case null:
      data = "/";
          break
    default:data;
        break
}
  return data;
})


Vue.filter('apkUpdate2', function( data ){

  switch (data) {
    case "":
      data = "/";
          break
    case null:
      data = "/";
          break
    case "0":
      data = "/";
          break  
    case "0.00":
      data = "/";
          break            
    default:data;
        break
}
  return data;
})


Vue.filter('sign', function( data ){
  if(data == ''){
    return "/"
  }
  if(data == null){
    return "/"
  }

  if (data>0) {
    return "+"+data
  } else {
    return data
  }

})

Vue.filter('qdsta', function( data ){
  if(data == 10) {
    return '正常'
  } else if(data == 20) {
    return '关闭'
  } else {
    return '删除'
  }

})
Vue.filter('astatic', function( data ){
  if(data == 10) {
    return '未审核'
  } else if(data == 20) {
    return '已通过'
  } else {
    return '未通过'
  }

})

Vue.filter('isok', function (value) {
  switch (value) {
    case 0:
      value = "否";
      break;
    case 1:
      value = "OK镜";
      break;
      case 2:
      value = "框架镜";
      break;
      case 3:
      value = "隐形眼镜";
      break;
  }
    return value;
})

Vue.filter('slvl1', function (value) {
  if(value == 1){
    value = "幼儿园";
  }else if(value == 2){
    value = "小学";
  }else if(value == 3){
    value = "初中";
  }else if(value == 4) {
    value = "高中";
  }
    return value;
})

Vue.filter('funid1', function (value) {
  if(value == 13){
    value = "远视";
  }else if(value == 14){
    value = "正视";
  }else if(value == 15){
    value = "低度近视";
  }else if(value == 16) {
    value = "中度近视";
  }else if(value == 17) {
    value = "高度近视";
  }else if(value == 18) {
    value = "超高度近视";
  }else if(value == 19) {
    value = "重高度近视";
  }
    return value;
})


Vue.filter('age1', function (value) {
  var date = Math.floor(value/12) + "岁" + Math.floor(value % 12) + "月";
  return  date;
 })

 Vue.prototype.$dateFormat = function(date,formatStr){
    if(!date){
        date = new Date()
    }
    if(!formatStr){
        formatStr = 'yyyy-MM-dd HH:mm:ss'
    }
    let dateType = Object.prototype.toString.call(date)
    if(dateType != '[object Number]' && dateType != '[object Date]'){
        console.error(`dateFormat传入错误时间格式==>${date}`);
        return new Error(`dateFormat传入错误时间格式==>${date}`)
    }
    if(dateType == '[object Number]'){
        date = new Date(date)
    }
    if(typeof formatStr == 'string'){
        let format = formatStr
        let fun = (val)=>{
            return  (val + '')[1] ? val : '0' + val
        }
        formatStr = (dateArr)=>{
            let a = Object.keys(dateArr)
            a.forEach(element => {
                format = format.replaceAll(element,fun(dateArr[element]))
            });
            return format
        }
    }else if(typeof formatStr == 'function'){
        
    }else{
        console.error(`dateFormat传入格式错误==>${formatStr}`);
        return new Error(`dateFormat传入格式错误==>${formatStr}`)
    }
    let newDate = new Date(date)
    let objDate = {}
    objDate['yyyy'] = newDate.getFullYear()
    objDate['MM'] = newDate.getMonth() + 1
    objDate['dd'] = newDate.getDate()
    objDate['HH'] = newDate.getHours()
    objDate['mm'] = newDate.getMinutes()
    objDate['ss'] = newDate.getSeconds()
    return formatStr(objDate)
}