import {
    get,
    editpost,
    post
} from '../http.js'

const login = {
    // 点击编辑获取信息
    login(data) {
        return get('/api/login', data)
    },
}
// 用户管理
const user = {
    // 点击编辑获取信息
    get(data) {
        return get('/sysapi/sysuser/info', data)
    },
    // 更新表格数据
    updateList(data) {
        return post('/sysapi/sysuser/list', data)
    },
    // 新增
    add(data) {
        return editpost('/sysapi/sysuser/add', data, 'sysuser')
    },
    // 编辑
    edit(data) {
        return editpost('/sysapi/sysuser/update', data, 'sysuser')
    },
    // 删除
    del(data) {
        return post('/sysapi/sysuser/del', data)
    },
    //重置密码
    reset(data) {
        return post('/sysapi/sysuser/reset', data)
    },
    //修改密码
    editpwd(data) {
        return post('/sysapi/sysuser/editpwd', data)
    },
}

// 组织机构管理
const dept = {
    // 点击编辑获取信息
    get(data) {
        return get('/sysapi/sysdept/info', data)
    },
    // 更新表格数据
    updateList(data) {
        return post('/sysapi/sysdept/list', data)
    },
    // 新增
    add(data) {
        return editpost('/sysapi/sysdept/add', data, 'sysdept')
    },
    // 编辑
    edit(data) {
        return editpost('/sysapi/sysdept/update', data, 'sysdept')
    },
    // 删除
    del(data) {
        return post('/sysapi/sysdept/del', data)
    },
}

// 角色管理
const role = {
    // 点击编辑获取信息
    get(data) {
        return get('/sysapi/sysrole/info', data)
    },
    // 更新表格数据
    updateList(data) {
        return post('/sysapi/sysrole/list', data)
    },
    // 新增
    add(data) {
        return editpost('/sysapi/sysrole/add', data, 'sysrole')
    },
    // 编辑
    edit(data) {
        return editpost('/sysapi/sysrole/update', data, 'sysrole')
    },
    // 删除
    del(data) {
        return post('/sysapi/sysrole/del', data)
    },
}

// 职位管理
const job = {
    // 点击编辑获取信息
    get(data) {
        return get('/sysapi/sysjob/info', data)
    },
    // 更新表格数据
    updateList(data) {
        return post('/sysapi/sysjob/list', data)
    },
    // 新增
    add(data) {
        return editpost('/sysapi/sysjob/add', data, 'sysjob')
    },
    // 编辑
    edit(data) {
        return editpost('/sysapi/sysjob/update', data, 'sysjob')
    },
    // 删除
    del(data) {
        return post('/sysapi/sysjob/del', data)
    },
}

// 模块管理
const menu = {
    // 点击编辑获取信息
    get(data) {
        return get('/sysapi/sysmenu/info', data)
    },
    // 更新表格数据
    updateList(data) {
        return post('/sysapi/sysmenu/list', data)
    },
    // 新增
    add(data) {
        return editpost('/sysapi/sysmenu/add', data, 'sysmenu')
    },
    // 编辑
    edit(data) {
        return editpost('/sysapi/sysmenu/update', data, 'sysmenu')
    },
    // 删除
    del(data) {
        return post('/sysapi/sysmenu/del', data)
    },
}

export {
    login,
    user,
    job,
    role,
    menu,
    dept
};