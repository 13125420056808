import {
    post,
} from '../http.js'

const vipuser = {
    //列表
    postlist(data) {
        // console.log("post=>",data)
        return post('/pc/user/src/list', data)
    },
    //新增
    save(data) {
        // console.log(data)
        return post('/pc/user/src/save', data)
    },
    //获取关系
    parents(data) {
        return post('/pc/user/src/getParents', data)
    },
    // 获取检测设备
    getDev(data) {
        return post('/pc/user/src/getDev', data)
    },
    // 字典信息
    getinfo(data) {
        return post('/dict/getinfo', data)
    },

    // 用户档案新增
    addk(data) {
    return post('/pc/user/src/addk', data)
    },

    // 用户档案修改
    update(data) {
        console.log(data);
        return post('/pc/user/src/update', data)
        },

    // 用户视力档案列表
    daList(data) {
        return post('/pc/user/consult/daList', data)
    },
   // 眼轴echarts
   yz(data) {
    return post('/pc/user/consult/yz', data)
},
   // 屈光度echarts
   qgd(data) {
    return post('/pc/user/consult/qgd', data)
},
   // 裸眼echarts
   ly(data) {
    return post('/pc/user/consult/ly', data)
},

    // 用户档案基本信息
    shareInfo(data) {
        return post('/pc/user/consult/shareInfo', data)
    },

       // 医学防控方案
       fkList(data) {
        return post('/pc/user/src/fkList', data)
    }, 

    // 回显数据
       tinfo(data) {
        return post('/pc/user/consult/info', data)
    }, 
    // 获取渠道列表
    getSelList(data) {
        return post('/pc/user/src/getSelList', data)
    },
}

export default vipuser;