import {
    get,
    post,
    postfile
} from '../http.js'

const exInfo = {

    screeningAnalyst(data) {
        return post('/education/report/v1/screeningAnalyst', data)
    },
    performance(data) {
        return post('/education/report/v1/performance', data)
    },
    abnormal(data) {
        return post('/education/report/v1/abnormal', data)
    },
    visionDistribution(data) {
        return post('/education/report/v1/visionDistribution', data)
    },
    svisionDistribution(data) {
        return post('/education/report/v1/svisionDistribution', data)
    },
    myopiarate(data) {
        return post('/education/report/v1/myopiarate', data)
    },
    classmyopia(data) {
        return post('/education/report/v1/classmyopia', data)
    },
    clvision(data) {
        return post('/education/report/v1/clvision', data)
    },

}

export default exInfo;