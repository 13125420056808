import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    allData: [],
    sideData: [],
    headerPaths: '',
    sidePaths: [],
    isCollapse: false,
    tabsize: '',
    myMenu: [],
  },
  mutations: {
    getAlldata(state, val) {
      state.allData = val
    },
    getSidedata(state, val) {
      state.sideData = val
    },
    getHeaderPaths(state, val) {
      state.headerPaths = val
      state.sidePaths = []
    },
    getSidePaths(state, val) {
      state.sidePaths = val
    },
    getCollapse(state, val) {
      state.isCollapse = val
    },
    getTabsize(state, val) {
      state.tabsize = val
    },
    setmyMenu(state, val) {
      state.myMenu = val
    },
  },
  actions: {},
  modules: {}
})