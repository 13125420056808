import {
    get,
    post,
    postfile
} from '../http.js'

const screening = {
    //获取耗材类型拉下框选项
    list(data) {
        return post('/pc/qd/v1/screen/list', data)
    },
    export(data) {
        return post('/pc/qd/v1/screen/export', data)
    },
    sel(data) {
        return post('/pc/qd/v1/screen/sel', data)
    },
    sel3(data) {
        return post('/pc/qd/v1/screen/sel1', data)
    },
    yysel(data) {
        return post('/jw/qudao/v1/screeningsel', data)
    },

}

export default screening;