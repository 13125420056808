import {
    get,
    editpost,
    post
} from '../http.js'

const jmtest = {
    // 点击编辑获取信息
    get(data) {
        return get('/bfapi/jmtest/info', data)
    },
    // 更新表格数据
    updateList(data) {
        return post('/bfapi/jmtest/list', data)
    },
    // 新增
    add(data) {
        return editpost('/bfapi/jmtest/add', data, 'jmtest')
    },
    // 编辑
    edit(data) {
        return editpost('/bfapi/jmtest/update', data, 'jmtest')
    },
    // 删除
    del(data) {
        return post('/bfapi/jmtest/del', data)
    },
}

export default jmtest;