import {
    get
} from '../http.js'

const count = {
    // 渠道首页基本信息
    getCommonInfo(data) {
        return get('/pc/home/basis', data)
    },
    // 新增客户增长趋势信息
    getUsersAdd(data) {
        return get('/pc/home/trend', data)
    },
    // 近视程度人数
    getDeepCounts(data) {
        return get('/pc/home/myopia', data)
    },
    //月增用户统计
    getMonthAdd(data) {
        return get('/pc/home/userrise', data)
    },
    //视力分布统计
    getAllDeeps(data) {
        return get('/pc/home/myopia', data)
    },
    //用户渠道占比统计
    getAllUsers(data) {
        return get('/pc/home/qdscale', data)
    },
        //用户管理统计数据
        count(data) {
            return get('/pc/user/src/count', data)
        }
}

export default count;